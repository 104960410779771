// @flow
import * as React from 'react'
import { TextBlock, useBlock, PARAGRAPH_SMALL } from '@shift-marketing/shift-one-website-editor'

import { createClickActivity } from 'Actions'
import { Store } from 'Store'

import formatPhoneNumber from 'util/formatPhoneNumber'

import styles from './styles.module.scss'

import propertiesSchema from './properties.schema.json'

export default function FooterBar () {
    const { state: { campaign } } = React.useContext(Store)
    const { ref } = useBlock('footer.bar', propertiesSchema)

    return (
        <div ref={ref} className={styles.text}>
            <TextBlock
                blockId={'footer.details'}
                defaultContent={`
                    <p class="${PARAGRAPH_SMALL}">
                        ${[((campaign.client.address.line1 || '') + ' ' + (campaign.client.address.line2 || '')).trim(),
                            campaign.client.address.city,
                            campaign.client.address.province,
                            campaign.client.address.postalCode
                        ].join(' ')} | phone: ${formatPhoneNumber(campaign.client.phone)} | email: ${campaign.client.email || ''}
                    </p>
                    `}
                onAnchorClick={createClickActivity}
            />
        </div>
    )
}
