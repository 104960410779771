import React from 'react'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'

import supportedThemes from './themes/__supportedThemes'

const DonateOption = ({
    title,
    description,
    icon,
    onClick,
    center,
    small,
    active,
    theme,
}) => {
    return (
        <div className={cn(styles.donateBtn, {
            [styles['donateBtn--small']]: small,
            [styles.active]: active
        })} onClick={onClick} style={{
            '--primary-color': theme.primaryColor
        }}>
            {icon && <div className={styles.icon} style={{ backgroundImage: `url(${icon})` }}></div>}
            {title && <span className={cn({
                [styles.center]: center
            }, styles[theme.customTitleClassName])}>{title}</span>}
            {description && <p>{description}</p>}
        </div>
    )
}

export default withTheme(supportedThemes)(DonateOption)
