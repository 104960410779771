// @flow
import * as React from 'react'

import useTheme from 'hook/useTheme'

export default function withTheme (supportedThemes) {
    return function (WrappedComponent) {
        // Depending on an implementation detail is not smart... but there is no other way
        const isForwardRef = WrappedComponent.$$typeof === Symbol.for('react.forward_ref')

        function Themed (props, ref) {
            const theme = useTheme(supportedThemes)

            if (theme === null) {
                return <div>Loading theme...</div>
            }

            // Necessary for the presentation "Live Preview" due to the Safari iframe bug
            window.top.postMessage(document.body.scrollHeight, '*')

            if (isForwardRef && ref) {
                props = { ...props, ref: ref }
            }

            return <WrappedComponent theme={theme} {...props} />
        }

        if (isForwardRef) {
            return React.forwardRef(Themed)
        }

        return Themed
    }
}
