import React from 'react'
import { updateLead, createClickActivity } from 'Actions'
import { useTranslation } from 'react-i18next'
import { useBlock, TextBlock } from '@shift-marketing/shift-one-website-editor'
import { Store } from 'Store'

import Footer from 'components/Footer'
import SideBar from 'components/SideBar'
import Step1 from 'components/LeadGenForm/Step1'
import Payment from 'components/Payment'

import supportedThemes  from './themes/__supportedThemes'

import transDomain from './translations/index.translations'
import propertiesSchema from './properties.schema.json'

import styles from './styles.module.scss'
import withTheme from '../../hoc/withTheme'

export default withTheme(supportedThemes)(function Landing (props) {
    const { state, dispatch } = React.useContext(Store)
    const { ref, properties } = useBlock('landing.rightSide', propertiesSchema)
    const { t } = useTranslation(transDomain)
    const [submitted, setSubmitted] = React.useState(false)
    const firstName = state.lead.fields.firstName
    const isConquest = !state.lead.fields.firstName || state.lead.fields.firstName === ''

    const handleAbort = React.useCallback(() => {
        setSubmitted(false)
    }, [])

    const initialValues = React.useMemo(() => {
        const values = state.lead.fields

        values.anonymousDonation = state.lead.fields.anonymousDonation || false
        values.donationType = state.lead.fields.donationType || 'one-time'

        // has no donation selected by the user
        if (!values.hasOwnProperty('donationAmount') || values.donationAmount === null) {
            // if we have defaultDonationAmount set it as default
            if (state.campaign.website.defaultDonationAmount) {
                values.donationAmount = state.campaign.website.defaultDonationAmount
            }
        }

        // if only 1 donationDesignation set is as default
        if (state.campaign.website.donationDesignations && state.campaign.website.donationDesignations.length === 1) {
            values.donationDesignations = [{
                type: state.campaign.website.donationDesignations[0],
                value: values.donationAmount
            }]
        }
        // if we have defaultDonationDesignation set is as default
        if (state.campaign.website.defaultDonationDesignation &&
            (typeof values.donationDesignations === 'undefined' || values.donationDesignations === null)
        ) {
            values.donationDesignations = [{
                type: state.campaign.website.defaultDonationDesignation,
                value: values.donationAmount
            }]
        }

        return values
    }, [
        state.campaign.website,
        state.lead.fields
    ])

    return (
        <>
            {submitted ? <Payment onAbort={handleAbort} /> : null}

            <div className={styles.grid} style={{ '--primary-color': props.theme.primaryColor }}>
                <div className={styles.leftSide}>
                    <SideBar name={firstName}/>
                </div>

                <div className={styles.rightSide}>
                    <div ref={ref} style={{ backgroundColor: properties.backgroundColor }}>
                        <div className={styles.headerInfo}>
                            {isConquest
                                ? <h4><TextBlock
                                    className={styles.donationTitle}
                                    blockId="masthead.headingEmpty"
                                    defaultContent={t('masthead.headingEmpty')}
                                    values={{ ...state.lead.fields }}
                                    valuesSchema={state.campaign.mergeTags}
                                    locale={state.locale}
                                    inline
                                    onAnchorClick={createClickActivity}
                                /></h4>
                                : <h4><TextBlock
                                    className={styles.donationTitle}
                                    blockId="masthead.heading"
                                    defaultContent={t('masthead.heading')}
                                    values={{ ...state.lead.fields }}
                                    valuesSchema={state.campaign.mergeTags}
                                    locale={state.locale}
                                    inline
                                    onAnchorClick={createClickActivity}
                                /></h4>
                            }

                                <TextBlock
                                    className={styles.donationMessage}
                                    blockId="masthead.message"
                                    defaultContent={t('donationMessage')}
                                    values={{ ...state.lead.fields }}
                                    valuesSchema={state.campaign.mergeTags}
                                    locale={state.locale}
                                    onAnchorClick={createClickActivity}
                                />
                            </div>
                        <Step1
                            onSubmit={async (data, done) => {
                                try {
                                    let lead = {
                                        fields: data,
                                        isComplete: true
                                    }
                                    await updateLead(dispatch, state.code, lead)
                                    setSubmitted(true)
                                    done()
                                    window.scrollTo(0, 0)
                                } catch (err) {
                                    alert('Oops something went wrong')
                                    console.error(err)
                                    done()
                                }
                            }}
                            initialValues={initialValues}
                        />
                    </div>
                    <span><Footer /></span>
                </div>
            </div>
        </>
    )
})
