// @flow
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { TextBlock } from '@shift-marketing/shift-one-website-editor'
import { Currency } from 'i18n/NumberFormatter'
import { Store } from 'Store'
import cn from 'classnames'

import { createClickActivity } from 'Actions'

import withTheme from 'hoc/withTheme'

import transDomain from './translations/index.translations'

import DonateOption from 'components/DonateOption'
import * as Form from 'components/Form'

import withForm, { WithFormProps } from 'components/Form/Helpers/FormHOC'

import supportedThemes from './themes/__supportedThemes'

import styles from './styles.module.scss'

type Props = WithFormProps | {
    values: {
        donationType: ?string,
        customDonation: ?string,
        donationAmount: ?string,
        cardType: ?string,
        matchingGift: ?string,
        AdditionalInformation: ?String,
        anonymousDonation: ?boolean
    }
}

function Step1 (props: Props) {
    const { handleFieldChange, values, isLoading } = props
    const { t } = useTranslation(transDomain)
    const { state } = React.useContext(Store)
    const [ otherAmountActive, setOtherAmountActive ] = React.useState(null)

    const defaultDonationAmount = values.donationAmount || state.campaign.website.defaultDonationAmount

    const currencyFormatter = React.useMemo(
        () => Currency(state.currency, state.locale),
        [state.currency, state.locale]
    )

    const donationAmountOptions = React.useMemo(() => {
        return state.campaign.website.donationAmounts
    }, [state.campaign.website.donationAmounts])

    const onDonationOption1 = React.useCallback(value => {
        handleFieldChange('donationType', 'one-time')
    }, [handleFieldChange])

    const onDonationOption2 = React.useCallback(value => {
        handleFieldChange('donationType', 'monthly')
    }, [handleFieldChange])

    const handleOnAdditional = React.useCallback(value => {
        return handleFieldChange('AdditionalInformation', value)
    }, [handleFieldChange])

    const handleSetOtherAmountActive = React.useCallback(() => {
        setOtherAmountActive(true)
    }, [])

    const handleSetOtherAmountDisable = React.useCallback(() => {
        setOtherAmountActive(false)
    }, [])

    const defaultYearOptions = React.useMemo(() => {
        const maxDate = new Date().getFullYear()
        const dates = {}

        for (let i = maxDate; i >= 1900; i--) {
            dates[` ${i} `] = i
        }

        return dates
    }, [])

    values.anonymousDonation = values.anonymousDonation || false

    React.useEffect(() => {
        if (state.campaign['matching-gift-gateway']) {
            window.addEventListener('doublethedonation_company_id', function (event) {
                const companyId = event.detail
                const companyName = document.getElementsByName('doublethedonation_entered_text')[0]
                if (companyId && companyName) {
                    handleFieldChange('matchingGiftCompanyId', companyId, () => {
                        handleFieldChange('matchingGiftCompanyName', companyName.value)
                    })
                }
            }, false)
        }
    }, [state.campaign, handleFieldChange])

    const paymentGatewayConfig = React.useMemo(() => {
        const index = Object.keys(state.campaign['payment-gateways'])[0]
        return state.campaign['payment-gateways'][index]
    }, [state.campaign])

    React.useEffect(() => {
        if (paymentGatewayConfig?.monthlySubscriptionActive === false) {
            onDonationOption1()
        }
    }, [onDonationOption1, paymentGatewayConfig])

    React.useEffect(() => {
        if ((!donationAmountOptions.includes(defaultDonationAmount)) && defaultDonationAmount) handleSetOtherAmountActive()
        if (defaultDonationAmount && !values.donationAmount) handleFieldChange('donationAmount', defaultDonationAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultDonationAmount, donationAmountOptions, handleSetOtherAmountActive, handleFieldChange])

    return (
        <div>
            <Form.Row>
                <Form.RowColumns columnsTablet={1}>
                    {paymentGatewayConfig?.monthlySubscriptionActive && (<>
                        <div className={styles.title}>
                            <TextBlock
                                blockId={'title.frequency'}
                                defaultContent={t('frequency')}
                                inline
                                onAnchorClick={createClickActivity}
                            />
                        </div>

                        <Form.RowColumn size={1}>
                            <Form.Field>
                                <div className={styles.donateOptionGroup}>
                                    <DonateOption
                                        center
                                        small
                                        title={t('donateOptionGroup.one-time')}
                                        onClick={onDonationOption1}
                                        active={values.donationType === 'one-time'}
                                    />
                                    <DonateOption
                                        center
                                        small
                                        title={t('donateOptionGroup.monthly')}
                                        onClick={onDonationOption2}
                                        active={values.donationType === 'monthly'}
                                    />
                                </div>
                            </Form.Field>

                            <div className={styles.infoTextColor}>
                                {t('title', {
                                    type: values.donationType
                                })}
                            </div>
                        </Form.RowColumn>
                    </>)}

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.donationAmount'}
                            defaultContent={t('title2')}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1}>
                        <Form.Field>
                            <div className={styles.donationAmounts}>
                                {donationAmountOptions.map((donationAmount) => (
                                    <DonateOption
                                        key={donationAmount}
                                        center
                                        small
                                        title={currencyFormatter.format(donationAmount)}
                                        onClick={() => {
                                            // if a user changes the donation amount after he selected a designation
                                            if (values.donationDesignations && values.donationDesignations.length === 1) {
                                                const designation = values.donationDesignations[0]
                                                designation.value = donationAmount
                                                handleSetOtherAmountDisable()
                                                // update first field, use callback to update extra state
                                                handleFieldChange('donationAmount', donationAmount, () => {
                                                    handleFieldChange('donationDesignations', [designation])
                                                })
                                            } else {
                                                handleSetOtherAmountDisable()
                                                handleFieldChange('donationAmount', donationAmount)
                                            }
                                        }}
                                        active={values.donationAmount === donationAmount && !otherAmountActive}
                                    />
                                ))}

                                <DonateOption center small title='Other'
                                    onClick={() => {
                                        // if a user changes the donation amount after he selected a designation
                                        if (values.donationDesignations && values.donationDesignations.length === 1) {
                                            // prevent double click on "Other" value must not be null or 0
                                            const designation = values.donationDesignations[0]
                                            designation.value = values.donationAmount
                                            // update first field, use callback to update extra state
                                            handleSetOtherAmountActive()
                                            handleFieldChange('donationAmount', designation.value, () => {
                                                handleFieldChange('donationDesignations', [designation])
                                            })
                                        } else {
                                            handleSetOtherAmountActive()
                                            handleFieldChange('donationAmount', null)
                                        }
                                    }}
                                    active={otherAmountActive}
                                />
                            </div>
                        </Form.Field>
                    </Form.RowColumn>

                    {((otherAmountActive === true || (values.donationAmount && !donationAmountOptions.includes(values.donationAmount)))) &&
                        <Form.CurrencyField
                            placeholder={t('fields.donation')}
                            type='currency'
                            defaultValue={values.donationAmount || ''}
                            onChange={value => {
                                // if a user changes the donation amount after he selected a designation
                                if (values.donationDesignations && values.donationDesignations.length === 1) {
                                    const designation = values.donationDesignations[0]
                                    designation.value = value
                                    // update first field, use callback to update extra state
                                    handleFieldChange('donationAmount', value, () => {
                                        handleFieldChange('donationDesignations', [designation])
                                    })
                                } else {
                                    handleFieldChange('donationAmount', value)
                                }
                            }}
                            currency={state.currency}
                            locale={state.locale}
                            required={typeof values.donationAmount !== 'undefined' && !donationAmountOptions.includes(values.donationAmount)}
                        />
                    }

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.designation'}
                            defaultContent={t('title3')}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1}>
                        <Form.Field>
                            <Form.Dropdown
                                placeholder={'Select'}
                                options={state.campaign.website.donationDesignations}
                                required={true}
                                onChange={value => {
                                    handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                        type: value,
                                        value: values.donationAmount,
                                        other: value.indexOf('Other') === 0
                                    }] : null)
                                }}
                                defaultValue={values.donationDesignations
                                    ? (values.donationDesignations[0].type
                                        ? values.donationDesignations[0].other
                                            ? 'Other'
                                            : values.donationDesignations[0].type
                                        : null
                                    )
                                    : null
                                }
                            />
                        </Form.Field>
                    </Form.RowColumn>

                    {(values.donationDesignations?.[0].type.indexOf('Other') === 0 || values.donationDesignations?.[0].other) &&
                    <Form.RowColumn size={1}>
                        <Form.Field
                            required
                            defaultValue={values.donationDesignations
                                ? (values.donationDesignations[0].type
                                    ? values.donationDesignations[0].type.indexOf('Other') === 0
                                        ? ''
                                        : values.donationDesignations[0].type
                                    : null
                                )
                                : null
                            }
                            onChange={value => {
                                handleFieldChange('donationDesignations', (value !== '' && value !== null) ? [{
                                    type: value,
                                    value: values.donationAmount,
                                    other: true
                                }] : null)
                            }}
                            type='text'
                            placeholder={'Enter other designation'}
                        />
                    </Form.RowColumn>
                    }

                    {!props.theme.matchingGiftCompanyHidden && (
                        <>

                            <div className={styles.title}>
                                <TextBlock
                                    blockId={'title.matchingGift'}
                                    defaultContent={t('title4')}
                                    inline
                                    onAnchorClick={createClickActivity}
                                />
                            </div>

                            <Form.RowColumn size={1}>
                                <Form.Field>
                                    <div className={styles.dontaionGift}>
                                        <DonateOption center small title='None'
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'none')}
                                            active={values.matchingGiftCompany === 'none'}
                                            className={styles.option1}
                                      />
                                        <DonateOption center small title='My company'
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'own-company')}
                                            active={values.matchingGiftCompany === 'own-company'}
                                            className={styles.option3}
                                      />
                                        <DonateOption center small title="Spouse’s company"
                                            onClick={() => handleFieldChange('matchingGiftCompany', 'spouse-company')}
                                            active={values.matchingGiftCompany === 'spouse-company'}
                                            className={styles.option5}
                                      />
                                    </div>
                                </Form.Field>
                                {(values.matchingGiftCompany === 'spouse-company' || values.matchingGiftCompany === 'own-company') && values.matchingGiftCompany !== null && values.matchingGiftCompany !== 'none' &&
                                <Form.Field
                                    defaultValue={values.matchingGiftCompanyName || null}
                                    onChange={value => {
                                        handleFieldChange('matchingGiftCompanyName', `${value}`)
                                    }}
                                    type='text'
                                    placeholder={t('fields.companyName')}
                              />
                              }
                            </Form.RowColumn>

                        </>
                    )}

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.personalInfo'}
                            defaultContent={t('title5')}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1} className={cn(styles.row1, {
                        [styles.withoutClassYear]: props.theme.classYearHidden
                    })}>
                        <Form.Field
                            defaultValue={values.firstName || null}
                            onChange={value => {
                                handleFieldChange('firstName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.name')}
                                />
                        <Form.Field
                            defaultValue={values.lastName || null}
                            onChange={value => {
                                handleFieldChange('lastName', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.lastname')}
                        />
                        {!props.theme.classYearHidden && (
                            <Form.Field>
                                <Form.Dropdown
                                    placeholder={t('fields.year')}
                                    options={defaultYearOptions}
                                    required={false}
                                    onChange={value => {
                                        handleFieldChange('year', `${value}`)
                                    }}
                                    defaultValue={values.year || null}
                                />
                            </Form.Field>
                        )}
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row2}>
                        <Form.Field
                            defaultValue={values.addressLine1 || null}
                            onChange={value => {
                                handleFieldChange('addressLine1', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.address')}
                                />
                        <Form.Field
                            defaultValue={values.addressLine2 || null}
                            onChange={value => {
                                handleFieldChange('addressLine2', `${value}`)
                            }}
                            type='number'
                            required={false}
                            placeholder={t('fields.unit')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row3}>
                        <Form.Field
                            defaultValue={values.city || null}
                            onChange={value => {
                                handleFieldChange('city', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.city')}
                        />
                        <Form.Field
                            defaultValue={values.province || null}
                            onChange={value => {
                                handleFieldChange('province', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.state')}
                        />
                        <Form.Field
                            defaultValue={values.postalCode || null}
                            onChange={value => {
                                handleFieldChange('postalCode', `${value}`)
                            }}
                            type='text'
                            placeholder={t('fields.zipcode')}
                        />
                    </Form.RowColumn>
                    <Form.RowColumn size={1} className={styles.row4}>
                        <Form.Field
                            defaultValue={values.homePhone || null}
                            onChange={value => {
                                handleFieldChange('homePhone', `${value}`)
                            }}
                            type='phone'
                            placeholder={t('fields.phone')}
                        />
                        <Form.Field
                            defaultValue={values.email || null}
                            onChange={value => {
                                handleFieldChange('email', `${value}`)
                            }}
                            type='email'
                            placeholder={t('fields.email')}
                        />
                    </Form.RowColumn>

                    <Form.RowColumn size={1}>
                        <span className={styles.checkbox}>
                            <Form.Checkbox
                                defaultChecked={values.anonymousDonation}
                                onChange={() => {
                                    handleFieldChange('anonymousDonation', !values.anonymousDonation)
                                }}
                            />

                            <p>
                                {t('fields.checkbox')}
                            </p>
                        </span>
                    </Form.RowColumn>

                    <div className={styles.title}>
                        <TextBlock
                            blockId={'title.additionalInfo'}
                            defaultContent={t(props.theme.textAreaLabelTransKey)}
                            inline
                            onAnchorClick={createClickActivity}
                        />
                    </div>

                    <Form.RowColumn size={1}>
                        <Form.Textarea
                            defaultValue={values.AdditionalInformation || null}
                            onChange={handleOnAdditional}
                            type='text'
                            required={false}
                            rows={9}
                            placeholder={t('fields.AdditionalInformation')}
                        />
                    </Form.RowColumn>
                </Form.RowColumns>
            </Form.Row>

            <Form.Row>
                <div className={styles.center}>
                    <Form.SubmitBlock
                        loading={isLoading}
                        blockId={'leadGenForm.submit'}
                        label={t('fields.continue')}
                    />
                </div>
            </Form.Row>
        </div>
    )
}

Step1.defaultProps = {
    values: {
        matchingGift: 'None',
        anonymousDonation: false
    }
}

export default withForm(true)(withTheme(supportedThemes)(Step1))
