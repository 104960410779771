// @flow
import React from 'react'

import { Store } from 'Store'
import globalThemes from 'themes/__supportedThemes'

export default function useTheme (supportedThemes) {
    const [theme, setTheme] = React.useState(null)
    const { state } = React.useContext(Store)

    React.useEffect(() => {
        (async () => {
            if (!state.theme) return

            const themeName = computeStyle(state.campaign, supportedThemes)

            const theme = await loadTheme(themeName, supportedThemes, state.campaign)
            if (typeof theme.default !== 'function') {
                setTheme(theme.default)
            } else {
                setTheme(theme.default(state.theme))
            }
        })()
    }, [state.campaign, state.theme, supportedThemes])

    return theme
}

function computeStyle (campaign, supportedThemes): string {
    const { style } = campaign

    // we have a predefined style, so return that
    if (typeof style !== 'undefined' && style !== '' && style !== null) {
        if (!globalThemes.hasOwnProperty(style)) {
            const parts = style.split('-')
            parts.pop()

            if (parts.length > 0) {
                return computeStyle({ ...campaign, style: parts.join('-') }, supportedThemes)
            }
            return 'base'
        }
        return style
    }
}

function loadTheme (themeName, supportedThemes, campaign) {
    const availableThemes = Object.keys(supportedThemes)

    let module = availableThemes.find(theme => theme === campaign.id)

    if (!module) {
        module = supportedThemes.hasOwnProperty(themeName) ? themeName : 'base'
    }

    if (supportedThemes.hasOwnProperty(themeName) && supportedThemes[themeName].indexOf('pages/') === 0) {
        return import(
            /*
            webpackInclude: /pages\/(.+)\/themes\/(\w-?)+\.js$/,
            webpackExclude: /__supportedThemes.js$/,
            webpackChunkName: "themes",
            webpackMode: "lazy-once"
        */
            `pages/${supportedThemes[module].replace(/^pages\//, '')}`
            )
    }
    return import(
        /*
            webpackInclude: /components\/(.+)\/themes\/(\w-?)+\.js$/,
            webpackExclude: /__supportedThemes.js$/,
            webpackChunkName: "themes",
            webpackMode: "lazy-once"
        */
        `components/${supportedThemes[module].replace(/^components\//, '')}`
        )
}
