// @flow
import React, { useCallback } from 'react'

import { Store } from 'Store'
import { sendAutoresponder } from 'Actions'

import styles from './styles.module.scss'
import MatchingGiftForm from '../MatchingGift/Form'
import ModalContainer from '../ModalContainer'
import Button from '../Button'
import { Currency } from '../../i18n/NumberFormatter'
import { withRouter } from 'react-router'
import { ImageBlock, useBlock } from '@shift-marketing/shift-one-website-editor'
import propertiesSchema from './properties.schema.json'

export default withRouter(function SuccessModal ({
    location,
    match,
    history,
    paymentResponse,
    donationType
}) {
    const { state, dispatch } = React.useContext(Store)
    const { properties } = useBlock('footer', propertiesSchema)
    const currencyFormatter = Currency(state.currency, state.locale)

    React.useEffect(() => {
        (async () => {
            if (state.campaign.hasOwnProperty('autoresponder')) {
                await sendAutoresponder(dispatch)
            }
        })()
    }, [dispatch, state.campaign])

    const handleClick = useCallback(
        () => {
            const currentUrl = window.location.pathname + window.location.search
            const homeUrl = `/${match.params.url}${location.search}`
            if (currentUrl === homeUrl) {
                window.location.reload()
            } else {
                history.push(homeUrl)
                window.scrollTo(0, 0)
            }
        },
        [ history, location, match ]
    )

    React.useEffect(() => {
        if (window.doublethedonation && state.campaign['matching-gift-gateway'] && paymentResponse) {
            window.DDCONF = {
                API_KEY: state.campaign['matching-gift-gateway'].publicKey,
                COMPANY: state.lead.fields.matchingGiftCompanyId
            }
            window.doublethedonation.plugin.load_plugin()
            window.doublethedonation.plugin.load_config()
            window.doublethedonation.plugin.set_donation_identifier(paymentResponse.id)
            window.doublethedonation.plugin.set_donation_campaign(state.campaign.name)
            if (state.lead.fields.matchingGiftCompanyId) {
                window.doublethedonation.plugin.set_company(state.lead.fields.matchingGiftCompanyId)
            } else {
                const domain = window.doublethedonation.integrations.core.strip_domain(state.lead.fields.email)
                window.doublethedonation.plugin.email_domain(domain)
            }
        }
    }, [ paymentResponse, state.campaign, state.lead ])

    return <>
        {state.campaign['matching-gift-gateway'] && <MatchingGiftForm/>}

        <ModalContainer
            show={true}>
            <div className={styles.body} style={{
                '--primary-font': properties.font.heading,
                '--secondary-font': properties.font.body,
                '--secondary-color': properties.headingColor,
                '--button-text-color': properties.bodyColor
            }}>
                <ImageBlock
                    blockId="sidebar.logo"
                    defaultImage="$.logo.regular"
                    resize="both"
                    maxWidth="100%"
                    title="Logo"
                />
                <div className={styles.description}>
                    <div>
                        <h2>
                            Thank you, {state.lead.fields.firstName}.
                        </h2>
                        <p>
                            {donationType === 'one-time' && `Your ${currencyFormatter.format(state.lead.fields.donationAmount)} donation has been submitted.`}
                            {donationType === 'monthly' && `Your ${currencyFormatter.format(state.lead.fields.donationAmount)} monthly recurring donation has been submitted.`}
                        </p>
                    </div>
                    <div className={styles.btnContainer}>
                        {state.campaign['matching-gift-gateway'] && <div id="dd-container"/>}
                        <Button
                            className={styles.btn}
                            onClick={handleClick}
                            >
                            End your session
                        </Button>
                    </div>
                </div>
            </div>
        </ModalContainer>
        }
    </>
})
