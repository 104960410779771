// @flow
import React from 'react'
import ReactPlayer from 'react-player'

import styles from './styles.module.scss'

type Props = {
    url: string
}

function VideoPlayer (props: Props, ref) {
    const [playing, setPlaying] = React.useState(false)

    React.useImperativeHandle(ref, () => ({
        play: () => setPlaying(true),
        stop: () => setPlaying(false)
    }))

    const handleError = React.useCallback(ex => {
        console.error(ex)
    }, [])

    return (
        <div className={styles.wrapper}>
            <ReactPlayer
                url={props.url}
                playing={playing}
                muted={false}
                loop={false}
                onError={handleError}
                width='100%'
                height='100%'
                controls
                vimeo={{
                    autoplay: false,
                }}
            />
        </div>
    )
}

export default React.forwardRef(VideoPlayer)
